<template>
  <div class='profile'>
     <v-container>
       <v-col cols='10' offset="1" align='center'>
        <v-card-title class='text-center justify-center py-6'>
          <h3 class='font-weight-bold display-1'>
              Modificar información</h3>
        </v-card-title>
        <div>
          <v-col cols='12' sm='12' md='12'>
            <h2 class='text-center'>Datos Perfil</h2>
          </v-col>
        </div>
        <v-row>
        <v-col
          cols='12'
          offset-md='2'
          md='8'
          offset-sm='2'
          sm='8'
          offset-lg='4'
          lg='4'
        >
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols='12'
          offset-md='2'
          md='8'
          offset-sm='2'
          sm='8'
          offset-lg='4'
          lg='4'
        >
          <v-form>
            <v-text-field
              label='Dirección de e-mail'
              v-model='email'
              disabled
            >
            </v-text-field>
            <div class='text-center'>
              <v-btn color='success' @click='modificar'><v-icon left>
              mdi-pencil</v-icon> Modificar </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' offset-md='3' md='6' offset-sm='2' sm='8'>
          <v-alert
            :value='alertOk'
            border="bottom"
            color="green"
            dense
            elevation="5"
            prominent
            type="success"
          >
          <v-row align='center'>
              <v-col class='grow'>{{ this.mensaje }}</v-col>
             </v-row>
          </v-alert>
          <v-alert
            :value='alertError'
            border="bottom"
            color="red"
            dense
            elevation="8"
            prominent
            type="error"
          >
          <v-row align='center'>
              <v-col class='grow'>{{ this.mensaje }}</v-col>
             </v-row>
          </v-alert>
        </v-col>
        </v-row>
        </v-col>
      </v-container>
      </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Profile',
  data: () => ({
    email: '',
    password: '',
    telefono: '',
    nombre: '',
    apellido: '',
    cuit: '',
    dni: '',
    razonSocial: '',
    sectorProductivo: '',
    mensaje: '',
    tipoCliente: '1',
    user: [],
    client: [],
    token: localStorage.getItem('token'),
    alertOk: false,
    alertError: false,
  }),
  methods: {
    modificar() {
      axios
        .post(`${API_URL}api/usuarios/editar`, {
          email: this.email,
          nombre: this.nombre,
          apellido: this.apellido,
          cuit: this.cuit,
          dni: this.dni,
          razonSocial: this.razonSocial,
          sectorProductivo: this.sectorProductivo,
          tipoCliente: this.tipoCliente,
          rol_id: 1, // CAMBIAR
          id: localStorage.getItem('user_id'),
          token: this.token,
        })
        .then((response) => {
          if (response.data.status === 'error') {
            this.mensaje = response.data.message;
            this.alertError = true;
          } else {
            this.alertError = false;
            this.mensaje = response.data.message;
            this.alertOk = true;
            window.setInterval(() => {
              this.alertOk = false;
            }, 2000);
          }
        })
        .catch(() => {
          this.mensaje = 'Hubo un problema al modificar los datos del usuario';
          this.alertError = true;
        });
    },
    getUser() {
      const url = `${API_URL}api/usuarios/${localStorage.getItem('user_id')}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.user = response.data.user;
        this.client = response.data.client;
        this.email = this.user[0].email;
        this.telefono = this.user[0].telefono;
        this.nombre = this.client[0].nombre;
        this.apellido = this.client[0].apellido;
        this.cuit = this.client[0].cuit;
        this.dni = this.client[0].dni;
        this.razonSocial = this.client[0].razonSocial;
        this.sectorProductivo = this.client[0].sectorProductivo;
        this.tipoCliente = this.client[0].tipoCliente;
      });
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            this.getUser();
          });
      }
    },
  },
  created() {
    this.redireccion();
  },
};
</script>
